.AuditingLogDetailPromptInfo {
    &.MuiAlert-root {
        margin: 0 0 10px 0;
    }

    & .MuiAlert-message {
        white-space: pre-line;
    }

    & .MuiAlert-action {
        padding: 4px 0 0 0;
    }
}