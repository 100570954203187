body {
  margin: 0;
  font-family: var(--global-font-family);
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  background-color: #FFFFFF;
}

.App {

  &.MuiGrid-root {
    margin: 0;
    width: 100%;
    padding: 30px 50px 0 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &.admin, &.account-creation, &.pricing {
    &.MuiGrid-root {
      padding: 0;
    }
  }
}


input:focus {
  --tw-ring-shadow: 0 0 #000 !important;
}