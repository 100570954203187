.noModelsEnabledPanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.noModelsEnabledErrorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.noModelsEnabledErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 80%;

  & > div {
    padding: 20px;
    border-radius: 200px;
    border: 8px solid rgba(108, 196, 208, 0.25);
  }

  & > h3 {
    color: var(--global-text-primary-color);
    font-size: 35px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }

  & > h5 {
    color: var(--global-text-primary-color);
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }
}