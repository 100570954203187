.chipsLabelTeamWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.chip.MuiButtonBase-root {
  border-radius: 8px;
  margin-bottom: 5px;
  margin-right: 5px;

  &.chipCategory > .MuiChip-label {
    color: #523B7E;
  }

  & > .MuiChip-label {
    color: var(--card-text-color);
    font-family: var(--global-font-family);
    font-size: 13px;
    font-weight: 600;
  }

}

.chip.chipLabel {
  background: #6BC5D1;
}

.chip.chipCategory {
  background: rgba(241, 234, 255, 0.96);
}
