@use 'src/styles/admin';

.aiModelsControlContainer {
  @include admin.container;

  .aiModelsControlTitle.MuiTypography-root {
    @include admin.title;
    margin-bottom: 10px;
  }

  .aiModelsControlSectionTitle.MuiTypography-root {
    @include admin.sectionTitle;
    margin-bottom: 25px;
  }

}

.aiModelsTable {
  border: 1px solid rgba(128, 128, 128, 0.25);
  padding-left: 15px;
  padding-bottom: 25px;
  border-radius: 4px;
}

.aiModelRow {
  align-items: center;
  padding: 20px 0 5px 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.25);

  & > div {
    display: flex;
    align-items: center;

    & > p {
      margin-left: 15px;
      color: var(--global-text-primary-color);
    }

    &:nth-child(2),
    &:nth-child(3) {
      justify-content: center;
    }

  }

  &.disabled > div > * {
    color: #ADB3B7;
  }

}

.aiModelColumnIcons {
  padding-left: 25px;

  & > svg {
    cursor: pointer;
    color: var(--global-primary-color);
  }

  &.disabled > * {
    color: #ADB3B7;
  }
}

.switchModelIcon {
  border-left: 1px solid rgba(128, 128, 128, 0.25);
  padding-left: 10px;
}

.noModelsEnabledWarning {
  margin: 10px 0;
}

.newAiModelContainer {

  & > h5 {
    color: var(--global-text-primary-color);
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 20px 0;
  }
}

.addAiModelButton.MuiButtonBase-root {
  margin-top: 15px;
  color: var(--Dark-Aquamarine, #006D7A);
  font-size: 16px;
  font-weight: 500;
}

.connectAiButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

button.connectAiButton.MuiButtonBase-root {
  margin-top: 10px;
  border-radius: 3px;
  border: 1px solid var(--Dark-Aquamarine, #006D7A);
  background: var(--Dark-Aquamarine, #006D7A);
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), var(--global-primary-color, #006D7A);
  }
}