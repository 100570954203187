.groupUsersByModelAccessContainer {
  padding: 23px 30px;

  & .titleWrapper {
	padding-bottom: 19px;
	border-bottom: 1px solid rgba(222, 226, 230, 0.81);
	align-items: center;
	margin-bottom: 15px;

	& .MuiTypography-h4 {
	  color: var(--color-text-default, #172B4D);
	  font-size: 20px;
	  font-weight: 700;
	  margin-bottom: 0;
	}
  }

  & .closerDrawerWrapper {
	display: flex;
	justify-content: end;
	align-self: start;
  }

  & .closeIcon {
	color: var(--Dark-Aquamarine, #006D7A);
  }

  .userRow {
	border-bottom: 1px solid #DEE2E6;
	padding: 9px 7px;
  }

  .modelIcon {
	background-color: #44546F;
  }
}