.enabledAiModelsContainer, .enabledAiModelsByGroupContainer {
  padding: 23px 30px;

  & .enabledAiModelsTitleWrapper {
    padding-bottom: 19px;
    border-bottom: 1px solid rgba(222, 226, 230, 0.81);

    & .MuiTypography-h4 {
      color: var(--color-text-default, #172B4D);
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    & .MuiTypography-body1 {
      color: var(--global-text-primary-color);
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.007px;
    }
  }

  & .enabledAiModelsExplanation.MuiTypography-body1 {
    margin-top: 15px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  & .saveDisabledModelsButtonContainer {
    position: fixed;
    bottom: 23px;
    right: 30px;
  }

  & .enabledAiModelsUserName {
    padding-left: 5px;
  }

  & .closerDrawerWrapper {
    display: flex;
    justify-content: end;
  }

  & .closeIcon {
    color: var(--Dark-Aquamarine, #006D7A);
  }

  & .enabledAiModelsListContainer {
    overflow-y: auto;
    height: 80vh;
    overflow-x: hidden;
    padding-right: 5px;
  }
}