.budgetStatisticsContainer {
	flex-grow: 1;

	.budgetStatisticsTitle.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.budgetStatisticsAmount.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.budgetStatisticsPeriod.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}

	.budgetStatisticsDescription.MuiTypography-root {
		padding-top: 8px;
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}
	
	.budgetStatisticsRow {
		display: flex;
		justify-content: space-between;
	}

	.budgetProgressBar {
		border-radius: 4px;
		height: 8px;

		&.mostlySpentBudget {
			background-color: #F9CAC0;

			& .MuiLinearProgress-barColorPrimary {
				background-color: #FF6633;
			}
		}

		&.goneBudget .MuiLinearProgress-barColorPrimary {
			background-color: #D6001C;
		}
	}
}
