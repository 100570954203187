.savePromptContainer {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;
}

.savePromptCompilationDescription.MuiPaper-root {
  padding: 8px 15px;
  border-radius: 0 0 15px 15px;
  background-color: rgba(209, 186, 253, 0.30);
  box-shadow: none;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.labelCreatableSelect {
  & input, & input:focus  {
    box-shadow: none;
  }

  & [class$="-placeholder"] {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.30);
    padding: 14.5px 6px;
  }
}

.savePromptTitle {
  display: flex;
  justify-content: space-between;
}

.textFieldInput.textFieldPromptCompilation {
  margin-bottom: 0;
}

.savePromptCompilationContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.savePromptCompilationDescription {
  margin-top: 0;
  margin-bottom: 15px;



  & > span {
    font-size: 16px;
  }
}

.saveChatDescriptionText {
  margin-bottom: 15px;
  font-size: 16px;
}

.textFieldPromptDescription .MuiOutlinedInput-input {
  box-shadow: none;
}

.savePromptButtonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: flex-end;
  align-self: flex-end;
  flex-grow: 1;
}

.settingsContainer,
.savePromptDescription {
  margin-top: 10px;
}

.privacyContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  & .privacyToggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 9px;
  }
}

.savePromptDescription{
  margin-bottom: 10px;
}

.settingsText.MuiTypography-root {
  font-size: 16px;
}

.promptCompilationField {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  flex-grow: 1;
  padding: 16px 14px;
  overflow: scroll;
}