.aiChartContainer {
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(68, 84, 111, 0.15);
}

.aiChartTitle.MuiTypography-root {
  padding-bottom: 16px;
  color: var(--global-text-primary-color);
  font-size: 24px;
  font-weight: 700;
}

.noDataAvailableMessageContainer.MuiGrid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 0;
  gap: 10px;
  flex-shrink: 0;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topUserTableHeader > .MuiTableCell-head {
  color: var(--global-text-primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.topUserTableRow > .MuiTableCell-body {
  color: var(--global-text-primary-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}