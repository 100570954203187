.inactive {
  filter: grayscale(1);
}

.promptFormWrapper {
  margin: 0;
}

.goBackIcon {
  background-color: var(--global-primary-color);
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  margin-right: 21px;
}

.aiTabContainer {
  & button {
    min-height: 48px;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;

    &.Mui-selected {
      border-bottom: 3px solid var(--global-primary-color);
      transition: all 0.3s ease-out;
    }
  }
}
