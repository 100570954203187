.promptTabs {
	margin-top: 60px;

  & > div > div > button {
	color: #151C33;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0;
	text-align: left;
	padding: 10px 20px;
	text-transform: none;
	border-radius: 4px;

	&:nth-child(2) {
	  margin: 0 20px;
	}
  }
}

.MuiTabs-indicator {
  display: none;
}

.promptTabs > div > div > button.Mui-selected {
  background-color: var(--global-primary-color);
  color: #FFFFFF;
  outline: none;
}

.homeTitleContainer {
	margin-top: 15vh;
	text-align: center;
	font-style: normal;
	line-height: normal;

	& > h3 {
		color: var(--Dark, #151C33);
		font-size: 50px;
		font-weight: 600;
	}

	& > p {
		margin-top: 25px;
		color: var(--Black, #000);
		font-size: 20px;
		font-weight: 500;
	}

	&:nth-child(2) {
		margin-bottom: 32px;
	}
}

.searchModal .MuiModal-backdrop{
  background-color: #E0EFF0;
}

.searchModalContainer {
  padding: 63px 100px;
}

.closeModalContainer {
  justify-content: flex-end;
  display: flex;
  padding: 18px;

	& svg {
	  color: var(--global-primary-color);
	}
}


.selectModelTooltip {
  display: flex;
  gap: 3px;
  align-items: center;
  margin-top: 4px;
  color: rgba(68, 84, 111, 0.70);
  & > .MuiTypography-root {
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.15px;
  }
}


.searchBar.MuiTextField-root {
  color: rgba(21, 28, 51, 0.73);
  max-height: 150px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  width: 100%;
  border-radius: 8px;
  background: #FFF;
  border-left: 0;
  overflow-y: auto;

  &.expanded {
	border-radius: 8px 8px 0 0;
  }

  & ::placeholder {
	color: rgba(21, 28, 51, 0.73);
	opacity: 1;
	font-size: 16px;
  }

  & .MuiOutlinedInput-input {
	box-shadow: none;
  }

  & .MuiOutlinedInput-root {
	& fieldset {
	  border-color: #091E4224;
	  border-left: 0;
	  border-right: 0;
	}

	&:hover fieldset {
	  border-color: #091E4224;
	  border-left: 0;
	  border-right: 0;
	}

	&.Mui-focused fieldset {
	  border: 1px #091E4224 solid;
	  border-left: 0;
	  border-right: 0;
	}
  }
}

.promptInputContainer {
  padding: 13px 15px;
  border-radius: 8px;
  border: 2px solid var(--tuquoise-purple-gradient, #80B6BC);
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.12);

  .promptFilesRow {
	padding: 6px;
	display: flex;
	justify-content: flex-end;
  }

  .promptSettingsRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
	margin-top: 15px;

	.promptOptimizationIcon {
	  fill: url(#promptOptimizationGradient);
	}

	.optimizationText {
	  color: var(--global-primary-color);
	  font-size: 16px;
	  font-weight: 600;
	  margin-left: 12px;
	}

	.settingsIcon {
	  cursor: pointer;
	  color: var(--global-primary-color)
	}

	.fileUploadButtonContainer {
		margin-left: auto;
		.MuiSvgIcon-root {
			color: var(--global-primary-color);
		}
	}
  }

}

.MuiChip-root.aiModelChip {
  margin: 0 5px;
  background-color: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.14);
  color: #523B7E;

  & > .MuiChip-label {
	opacity: 0.5;
	font-weight: 600;
	font-size: 12px;
  }

  &.selected {
	border-radius: 100px;
	border: 1px solid #523B7E;
	background: #FFF;

	& > .MuiChip-label {
	  color: #523B7E;
	  opacity: 1;
	}

	> svg {
	  color: rgba(134, 118, 165, 1);
	}
  }
}

.searchBarContainerStuck {
  border-radius: 0 0 8px 8px;
  background-color: #FAFAF9;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.20);
  padding: 0 20px 10px 20px;
}

.searchResultsContainer {
  margin-top: 25px;

  & > h4 {
	color: rgba(68, 84, 111, 0.82);
	font-size: 20px;
	font-weight: 700;
  }

  & > p {
	font-family: var(--global-font-family);
	font-size: 16px;
	font-weight: 500;
	margin-top: 21px;
  }
}


.fixedInfoAlert.MuiAlert-root {
  border-radius: 4px;
  background-color: rgba(229, 246, 253, 1);
  color: rgba(1, 67, 97, 1);
  font-family: var(--global-font-family);
  font-size: 15px;
  font-weight: 500;

  & > .MuiAlert-icon > svg {
	color: rgba(2, 136, 209, 1);
  }
}