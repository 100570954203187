.budgetDetailContainer {
  border-radius: 12px;
  border: 1px solid rgba(68, 84, 111, 0.15);
  padding: 13px;

  & .budgetTitleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& h3 {
	  color: var(--global-text-primary-color);
	  font-size: 18px;
	  font-weight: 600;
	  line-height: 28px;
	}

	& button {
	  margin-left: 5px;
	}
  }

  & .budgetFieldsContainer {
	border-bottom: 1px solid rgba(68, 84, 111, 0.12);
	margin: 15px 0;
	padding-bottom: 13px;

	& .budgetFieldContainer {
	  display: flex;
	  margin: 9px 0;
	  font-size: 14px;
	  line-height: 15px;
	  letter-spacing: 0.007px;
	  color: var(--global-text-primary-color);

	  :nth-child(1) {
		font-weight: 600;
		line-height: 20px;
	  }
	}
  }

  & .budgetAmountTitle {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 13px;
  }
}