.groupSettings {
  padding: 30px 15px 15px 15px;

  .titleContainer {
	border-bottom: 1px solid #E0E0E0;
	margin-bottom: 19px;
	display: flex;
	justify-content: space-between;
  }

  .title {
	color: #172B4D;
	font-size: 18px;
	font-weight: 700;
	padding: 16px 0;
  }

  .groupAiModelDetailButton {
	justify-content: right;
  }

  .groupAiModelCount.MuiGrid-item {
	justify-content: left;
  }

  .setupAccessButton {
	margin: 11px 0;
  }
}