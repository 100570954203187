.appMenuMainItemContainer {
    &.MuiListItem-root {
        padding: 0;

    }
    
    .MuiListItemButton-root:hover {
        background-color: #F3EFFA73;
    } 

    &.selected {
        background-color: #F3EFFA73;

        .MuiTypography-root {
            color: #825ECA;
            font-weight: 600;
        }

        .MuiSvgIcon-root {
            color: #825ECA;
        }
    }
}