.modelSelector.MuiInputBase-root {
  border-radius: 22px;
  border: 1px solid rgba(0, 109, 122, 0.27);

  &.Mui-focused {
    border: none;
  }
}

.gradient-border {
  --border-width: 3px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  background: #FFFFFF;

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
                    60deg,
                    hsl(224, 85%, 66%),
                    hsl(269, 85%, 66%),
                    hsl(314, 85%, 66%),
                    hsl(359, 85%, 66%),
                    hsl(44, 85%, 66%),
                    hsl(89, 85%, 66%),
                    hsl(134, 85%, 66%),
                    hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: 23px;
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.selectModelIcon {
  margin-right: 8px;
}

.modelLabel.MuiFormLabel-root {
  margin-top: -5px;
  color: var(--global-primary-color);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.arrowDownIcon, .modelLabel {
  color: var(--global-primary-color);
}


.modelSelectorWrapper {
  margin: 8px;
  width: 220px;
}

.aiModelContainer {
  margin-top: 13px;
  justify-content: center;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modelsRow {
  margin-top: 10px;
}