.AuditingLogTrail {
    &_Container {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100%;
    }

    &_Title {
        width: 100%;
        padding: 16px;
        border-bottom: 1px solid #E0E0E0;

        &.MuiTypography-root {
            color: var(--global-text-primary-color);
            font-size: 20px;
            font-weight: 700;
            line-height: 133.4%;
        }
    }

    &_ItemsWrapper {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 53px 1fr;
        grid-template-rows: 1fr;
    }

    &_VerticalDivider {
        grid-area: 1 / 1 / 2 / 2;
        border-right: 2px solid #44546F26;
    }

    &_Items {
        padding: 10px 43px 28px 29px;
        grid-area: 1 / 1 / 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: scroll;
        gap: 16px;
    }
}