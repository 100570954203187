.regeneratedMessagesSelector {
  display: flex;
  align-items: center;
}

.regeneratingMessageText {
  color: var(--global-primary-color);
}

.regeneratingMessage {
  margin-left: 8px;
}