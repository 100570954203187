.warningLevelSelect.Mui-focused legend {
  overflow: visible;

  & span {
	opacity: 1;
	top: -5px;
	position: relative;
  }
}

.warningLevelSelect.edit {
	margin-top: 15px;
}


.warningName {
  width: 120px;
  margin-right: 10px;
  color: #44546F;
  font-size: 14px;
  font-weight: 500;
  align-self: center;
}

.formatWarningName {
  width: 170px;
}

.formatIcon.MuiSvgIcon-root {
  margin-right: 10px;
  color: #44546F;
  width: 16px;
}

.warningLevel {
  color: #818A91;
  font-size: 14px;
  font-weight: 400;
  flex-grow: 4;
  align-self: center;

  .name {
	font-size: 13px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.16px;
	text-transform: capitalize;
  }

  .high {
	color: #9A4D2F;
  }

  .medium {
	color: #926D34;
  }

  .low {
	color: #9D983B;
  }
}

.editWarningContainer {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;

  & > p {
	color: #000;
	font-size: 14px;
	font-weight: 400;
  }
}

.editWarningHeader {
  display: flex;
  justify-content: space-between;

  & > h3 {
	color: #172B4D;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 15px;
  }

  & > svg {
	color: var(--global-primary-color);
	cursor: pointer;
  }

}

.saveWarningButtonContainer {
  position: absolute;
  bottom: 0;
  right: 24px;

  & > button {
	border-radius: 3px;
	border: 1px solid var(--Dark-Aquamarine, #006D7A);
	background: var(--Dark-Aquamarine, #006D7A);
	color: #ffffff;
	margin: 15px 0;

	&:hover {
	  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), var(--global-primary-color, #006D7A);
	}
  }
}

.textFieldInput.MuiTextField-root.configDrawer {
  margin-top: 15px;
}


.warningItemRow {
  margin: 4px 7px;
  display: flex;
  border-bottom: 1px solid rgba(128, 128, 128, 0.25);

  .accountDomainName {
	flex-grow: 1;
	align-content: center;
	color: #44546F;
  }
}

.newWarningTextfield.MuiTextField-root, .newWarningTextfield.MuiInputBase-root {
  margin: 22px 0 7px 0;
}

.addWarningButton {
  padding: 8px 22px;
}

.actionIcon.MuiButtonBase-root {
  color: var(--global-primary-color);

  &.disabled {
	color: grey;
  }
}


.warningNameContainer {
  border-radius: 4px;
  border: 1px solid #CCC;
  margin: 15px 0;
  padding: 16px 16px 23px 16px;
}

.warningFieldContainer {
  padding-right: 10px;
}

.warningSubtitle.MuiTypography-root {
  color: #151C33;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
}

.enableFormatItem {
  border-left: 1px solid #DDD;
  margin-left: 15px;
}

.formatWarningDisabled > *, .formatWarningDisabled .formatIcon.MuiSvgIcon-root{
  color: #ADB3B7;
}

.formatWarningDisabled .warningLevel{
  color: #818A91;

  .high {
	color: #CBA495;
  }

  .medium {
	color: #B9AC95;
  }

  .low {
	color: #CDCB9B;
  }
}