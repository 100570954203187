.markdownEditor {
    font: var(--global-font-family);
    border: 0;

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    box-sizing: content-box;
    background: none;
    height: auto;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    resize: none;
    padding: 0;

    & > div > div {
        padding: 0;

        p {
            margin: 7.5px;
        }

        p:first-child {
            margin-top: 0;
        }

        a {
            color: revert;
            text-decoration: revert;
        }

        h1, h2, h3, h4, h5, h6 {
            font-size: revert;
            font-weight: revert;
        }

        ul, ol {
            margin: revert;
        }

        pre {
            overflow: auto;
        }

        .headerCodeBlock {
            display: flex;
            align-items: center;
            background-color: #f8fcfd;
            padding: 8px 16px;
            border-bottom: 4px solid #FFFFFF;
            justify-content: flex-end;

            .copyButtonCodeBlock {
                width: 20px;
                height: 20px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    &.userMessageListNone > div > div + ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }


    & .sp-read-only, .cm-gutters {
        display: none;
    }

    & .sp-wrapper {
        background-color: var(--sp-colors-surface1);
    }

    & .cm-content {
        background-color: #f8fcfd;
    }

    & .cm-line {
        color: rgba(68, 84, 111, 0.65);

        .sp-syntax-punctuation,
        .sp-syntax-plain,
        .sp-syntax-property {
            color: rgba(68, 84, 111, 1);
        }

        .sp-syntax-definition,
        .sp-syntax-keyword {
            color: #8065E0;
        }

        .sp-syntax-string {
            color: #698317;
        }
    }
}