@mixin container {
    padding: 10px;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

@mixin title {
    color: #151C33;
    font-family: var(--global-font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

@mixin subtitle {
    color: #151C33;
    font-family: var(--global-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
}

@mixin sectionTitle {
    color: #151C33;
    font-family: var(--global-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
