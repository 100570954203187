.budgetHistoryContainer {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 100%;

    .budgetHistoryEmpty.MuiTypography-root {
        padding: 0px 13px;
        font-family: var(--global-font-family);
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: var(--global-text-primary-color);
    }
    
    .cardContainer {
        display: flex;
        flex-direction: column;
        border: 1px solid #adabab;
        border-radius: 12px;
        padding: 13px;
        gap: 16px;
    }

    .cardHeader.MuiTypography-root {
        font-family: var(--global-font-family);
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: var(--global-text-primary-color);
    }

    .cardPropertyKey.MuiTypography-root {
        font-family: var(--global-font-family);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: var(--global-text-primary-color);
    }

    .cardPropertyValue.MuiTypography-root {
        font-family: var(--global-font-family);
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.006500000134110451px;
        text-align: left;
        
        color: var(--global-text-primary-color);
    }
}