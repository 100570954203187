.taskBarContainer.MuiPaper-root,
.taskBar {
  background-color: #F5F4F7;
  box-shadow: none;
  border-radius: 10px;
}

.taskBarContainer {
  margin-top: 10px;
}

.taskBar {
  display: flex;
  justify-content: flex-end;
}

.taskBarButton.MuiButtonBase-root {
  border-radius: 6px;
  color: #523B7E;
  font-size: 14px;
  font-weight: 500;
  margin: 0 5px;

  &:hover {
    background: rgba(209, 186, 253, 0.39);
  }

  &:disabled {
    background: none;
    color: #ADB3B7;
    font-size: 14px;
    font-weight: 500;
  }
}