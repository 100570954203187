.createTeamContainer {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;
}

.createTeamDescription {
  margin: 10px 0;
}

.membersCreatableSelect {
  & input, & input:focus  {
    box-shadow: none;
  }

  & [class$="-placeholder"] {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.30);
    padding: 14.5px 6px;
  }
}

.createTeamTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(222, 226, 230, 0.81);
  padding-bottom: 15px;
}

.createTeamTitle {
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    margin: 0;
  }

}

.createTeamTitleIcon {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #44546F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  & > svg {
    color: #FFFFFF;
  }
}

.createTeamDescriptionText.MuiTypography-root {
  margin-bottom: 15px;
  font-size: 16px;
}

.textFieldTeamName .MuiOutlinedInput-input {
  box-shadow: none;
}

.createTeamName {
  margin-bottom: 20px;
}

.createTeamButtonContainer {
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.createTeamButton.MuiButton-root {
  margin-left: 10px;
}

.closeIcon, .memberCheckIcon {
  color: var(--global-primary-color);
}

.userAvatarIcon {
  background-color: rgba(68, 84, 111, 0.69);
}

.MuiAutocomplete-listbox > .MuiAutocomplete-option.MuiBox-root {
  justify-content: space-between;
  margin: 4px;

  & > div {
    color: #626F86;
    font-size: 14px;
    font-weight: 400;
  }
}