.EmptyTeams {
    &_Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    &_Text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    &_Title {
        &.MuiTypography-root {
            margin-top: 10px;
            color: var(--global-text-primary-color);
            font-size: 26px;
            font-weight: 500;
        }
    }

    &_Description {
        &.MuiTypography-root {
            color: var(--global-text-primary-color);
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 1360px) {
    .EmptyTeams {
        &_Container {
            max-width: 75%;
        }
    }
}