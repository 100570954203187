.appMenuMainItemsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: padding 200ms ease-in;

        &.open {
            padding: 23px 10px 15px 18px;
        }
        &.closed {
            padding: 23px 6px 15px 8px;
        }
    }
    .titleLogo {
        transition: display 200ms ease-in, opacity 200ms ease-in;

        &.open {
            display: block;
            opacity: 1;
        }
        &.closed {
            display: none;
            opacity: 0;
        }
    }
    .titleToggle {
        transition: transform 500ms;

        &.open {
            transform: scaleX(-1);
        }
        &.closed {
            transform: scaleX(1);
        }
    }
    .listHeader.MuiTypography-root {
        color: #18384EE5;
        font-family: var(--global-font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.15px;
        overflow: hidden;

        &.open {
            opacity: 1;
            padding: 12px 16px;
            height: 48px;
            transition: height 150ms ease-in 0ms, padding 150ms ease-in 0ms, opacity 200ms ease-in 150ms;
        }
        &.closed {
            opacity: 0;
            padding: 0px 16px;
            height: 0px;
            transition: height 150ms ease-in 200ms, padding 150ms ease-in 200ms, opacity 200ms ease-in 0ms;
        }
    }
    .footerContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;   
    }
}