.appMenuSideItem {
    width: 24px;
    height: 24px;
    
    &.MuiButtonBase-root {
        padding: 0;
    }

    .MuiSvgIcon-root {
        color: #18384ECC;
    }

    &.selected {
        border-radius: 3px;
        background-color: #CFE4E7;

        .MuiSvgIcon-root {
            color: #117A89;
        }
    }

    &Tooltip.MuiTooltip-tooltip {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        border-radius: 4px;
        background-color: #0F6E7B;
        color: white;
        font-family: var(--global-font-family);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }
}