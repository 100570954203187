.overallBudgetContainer {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;

  & .titleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(222, 226, 230, 0.81);
	padding-bottom: 15px;

	& .createTeamTitle {
	  display: flex;
	  align-items: center;

	  & .MuiTypography-root {
		margin: 0;
	  }

	}
  }

  & .alertContainer, & .recurrenceContainer, & .dateRangePickerContainer {
	margin: 15px 0;
  }

  & .recurrenceContainer .MuiFormGroup-root {
	margin-left: 20px;
  }

  & .buttonsContainer {
	position: absolute;
	bottom: 10px;
	right: 25px;

	& > button:first-child {
	  margin: 0 10px;
	}
  }

  .tabsContainer {
	border-bottom: 1px solid #adabab;

	& .Mui-selected {
	  border-bottom: 1px solid var(--global-primary-color);
	  transition: all 0.3s ease-out;
	}
  }

  .budgetDatePicker {
	width: 100%;
	display: inline;
  }

  .errorHint {
	color: #d32f2f;
	font-size: 0.75rem;
	margin-left: 14px;
  }
}

.rs-picker-popup-date {
  z-index: 9999;
}
