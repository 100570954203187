.AccountCreationAside {
    padding: 38px 51px;
    width: 100%;
    max-width: 417px;
    min-height: 100vh;
    height: 100%;
    background-color: #B5E2E81A;

    &_Header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 73px;
    }
    
    &_Logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 17px;

        & > .MuiTypography-root {
            margin: 0;
            color: #151C33;
        }

        & rect {
            fill: #523B7E;
        }
    }

    &_Title {
        &.MuiTypography-root {
            color: #006D7A;
            font-weight: 600;
        }
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        color: #569EA7B2;

        & .MuiStepLabel-iconContainer {
            & svg {
                width: 42px;
                height: 42px;
                color: transparent;
                border: 2px solid #569EA7B2;
                border-radius: 21px;

                & text {
                    fill: #569EA7B2;
                    font-weight: 600;
                }

            }

            &.Mui-active {
                & svg {
                    border: 2px solid #006D7A;
                    & text {
                        fill: #006D7A;
                        font-weight: 600;
                    }
                }
            }

            &.Mui-completed {
                & svg {
                    background-color: #006D7A;
                    & path {
                        width: 20px;
                        height: 20px;
                        fill: #E3F2F4;
                    }
                }
            }
        }

        & .MuiStepLabel-label {
            font-weight: 600;
            font-size: 18px;
            color: #569EA7B2;

            &.Mui-active {
                color: #006D7A;
                font-weight: 600;
                font-size: 18px;
            }

            &.Mui-completed {
                font-weight: 600;
                font-size: 18px;
                color: #569EA7B2;
            }
        }

        & .MuiStepLabel-root {
            padding: 0;
        }

        & .MuiStepConnector-root {
            margin-left: 20px;
            & > span {
                border-color: #569EA7B2;
                min-height: 53px;
            }
        }
    }
}