.advancedSettingsDialogContainer {
  & .title {
    width: 100%;
    color: var(--global-primary-color);
    display:flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
  
    & .closeDialog {
      margin-left: auto;
    }
  }
  
  & .fieldsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5em 1em;
    margin-top: 14px;
  }

  & .fieldLabel.MuiTypography-root {
    margin-top: 5px;
    font-size: 14px;
  }

  & .MuiDialogTitle-root {
    padding: 10px 0;
  }

  & .MuiDialogContent-root {
    padding: 20px 0;

    & p.MuiTypography-root {
      color: #44546F;
      font-size: 16px;
    }
  }

  & .MuiDialogActions-root {
    padding: 8px 0;
  }

}