.GroupStatistics {
    width: 100%;
    height: calc(100vh - 165px);
    padding: 15px 30px;
    background-color: #FAFAF9;

    & .panel {
        background-color: white;
    }

    // Cant use .header as it conflicts with headermenu.scss

    & .panelHeader {
        padding: 10px 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .title.MuiButton-root {
            color: #44546F;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: "Avenir Next";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 133.4%; /* 32.016px */
        }

        & .settings {
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    & .cards {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding: 0px 22px;
    }

    & .prompts, & .expenses {
        min-width: 450px;
    }

    & .members {
        min-width: 360px;
    }

    & .prompts, & .expenses, & .members {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 21px;
        padding: 13px 24px;
        border-radius: 12px;
        border: 1px solid rgba(68, 84, 111, 0.15);
        min-height: 100%;
        flex-wrap: wrap;
        flex-grow: 1;

        & .icon {
            min-width: 80px;
            max-width: 80px;
            min-height: 80px;
            max-height: 80px;
            border-radius: 52px;
            background: #F5F4F7;
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg.MuiSvgIcon-root {
                width: 30px;
                height: 30px;
                color: #523B7E;
            }
        }

        & .title.MuiTypography-root {
            color: #44546F;
            font-family: var(--global-font-family);
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
        }

        & .subtitle.MuiTypography-root {
            color: #44546F;
            font-family: var(--global-font-family);
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        & .promptEntry {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            & .title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
            }

            & .icon {
                &0 {
                    width: 14px;
                    height: 14px;
                    background-color: #B5E2E8;
                }

                &1 {
                    width: 14px;
                    height: 14px;
                    background-color: #D0C4F3;
                }

                &2 {
                    width: 14px;
                    height: 14px;
                    background-color: #A6BCFF;
                }
            }
        }
        & .data.MuiTypography-root {
            color: #44546F;
            font-family: var(--global-font-family);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
        }

    }

    & .dataGrid {
        padding: 20px;
    }

    & .aiAccessActionItem {
        & > div > svg {
            color: rgba(68, 84, 111, 0.86);
            margin-right: 5px;
        }
    }
}