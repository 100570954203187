.teamTableHeaderMoreMenu {

  & > .MuiPaper-root {
    width: 200px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 4px 20px 2px rgba(18, 82, 161, 0.20);
    margin: 10px 0;

    & > ul > li {
      padding: 10px 15px;
    }
  }

  .teamMenuItemText {
    color: #626F86;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
  }

  .teamMenuItemIcon {
    color: rgba(68, 84, 111, 0.86);
    margin-right: 5px;
  }
}