.listItemContainer {
  padding: 0;

  .listItemUserName {
    color: #626F86;
    font-size: 14px;
    font-weight: 400;
  }

  .deleteIcon {
    color: var(--global-primary-color);
  }

}