.AccountCreationError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 100px;
    gap: 36px;

    &_Icon {
        max-width: 100px;
        max-height: 100px;
        width: 100%;
        height: 100%;
        padding: 10px;
        
        border-radius: 50px;
        border: 7px solid rgba(108, 196, 208, 0.25);
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    &_Title.MuiTypography-root {
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        color: var(--global-text-primary-color);
    }

    &_Subtitle.MuiTypography-root {
        font-size: 20px;
        text-align: center;
        line-height: 26px;
        color: var(--global-text-primary-color);
    }
}
