
.AccountCreationWrapper {
    width: 100%;

    .AccountCreationContainer {
        display: grid;
        grid-template-columns: 1fr 3fr;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .MuiTypography-root {
            font-family: var(--global-font-family);
        }

        h3 {
            color: #151C33;
            text-align: center;
            font-size: 25px;
            font-weight: 700;
        }

        h4 {
            color: #172B4D;
            font-size: 18px;
            font-weight: 700;
        }

        button {
            text-transform: none;

            &.MuiButton-outlined, &.MuiButton-text {
                color: var(--global-primary-color);
                background-color: #FFFFFF;

                &:hover {
                    text-decoration: none;
                    background-color: rgba(0, 109, 122, 0.04);
                }
            }

            &.MuiButton-contained {
                background-color: var(--global-primary-color);
                color: #FFFFFF;

                &:hover {
                    background-color: var(--global-primary-color);
                }
            }

            font-size: 16px;
            font-weight: 500;
            line-height: 160%;
        }

        .AccountCreationForm_Action button {
            cursor: pointer;
            background-color: var(--global-primary-color);
            color: #FFFFFF;

            &.disabled {
                background: #00000033;
            }

        }
    }
}