.inspirationTabs {
  .MuiTabs-flexContainer {
    justify-content: center;
    gap: 20px;
  }

  & button {
    color: #808080;
    min-height: 48px;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;

    &.Mui-selected {
      border-bottom: 3px solid var(--global-primary-color);
      transition: all 0.3s ease-out;
    }
  }
}