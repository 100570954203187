.textFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin: 0 auto;
  width: 650px;
}

.textFieldInput.textFieldApiKey {
  margin-bottom: 20px !important;
}

.textFieldApiKey {
  width: 650px;
}

.saveButton {
  align-self: flex-end;
}