.AccountCreationForm_Models {
    &Container {
        border: 1px solid #CCCCCC;
        border-radius: 4px;
        padding: 16px 16px 23px 16px;
        gap: 14px;
    }

    &Item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #CCCCCC;
        padding: 6px 0px;
    }

    &Name, &Actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        color: var(--global-text-primary-color);
    }

    &Icon {
        color: var(--global-primary-color);

        &:hover {
            cursor: pointer;
        }

        &.--disabled {
            color: #CCCCCC;

            &:hover {
                cursor: initial;
            }
        }
    }

    &Switch {
        &.multipleActions {
            border-left: 1px solid #CCCCCC;
        }

        & > .MuiSwitch-switchBase.Mui-checked {
            color: var(--global-primary-color);
        }

        &.MuiSwitch-root > span.MuiSwitch-track {
            background-color: var(--global-primary-color);
        }

    }
}

.AccountCreationForm_NewModel {
    &Creatable {
        &.hasError {
            border: 1px solid #D3302F;
            border-radius: 5px;
  
            & [class$="-placeholder"] {
            color: #D3302F;
            font-weight: 500;
            }
        }
  
        & > div > div > div:nth-child(2) > input {
        padding: 8px 6px !important;
        }
        & > div > div > div:nth-child(2) > input, [class$="-placeholder"] {
            color: rgba(0, 0, 0, 0.30);
            padding: 9px 6px !important;
            box-shadow: none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &SubmitWrapper {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }
}
