.expenditureContainer {
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 12px;
	border: 1px solid rgba(68, 84, 111, 0.15);
	padding: 16px 0;
	display: flex;
	justify-content: space-around;
	flex-direction: column;

	&.noBudget {
		justify-content: flex-start;
	}

	.expenditureTitle.MuiTypography-root {
		padding-bottom: 12px;
		color: var(--global-text-primary-color);
		font-size: 24px;
		font-weight: 700;
	}
	.expenditureRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 16px;

		&.noBudget {
			flex-direction: column;
			align-items: flex-start;
			padding: 16px;

			& .totalCostContainer {
				margin-bottom: 16px;
			}

			& .breakdownContainer {
				margin-top: 16px;
				width: 100%;
			}

			& .totalCostContainer,
			& .breakdownContainer {
				margin-left: 22px;
			}
		}
	}
	.expenditureSeparator.MuiDivider-root {
		margin: 9px 0;
	}
	.totalCostContainer {
		padding-right: 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.totalCostTitle.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-size: 16px;
		font-weight: 600;
	}
	.totalCostIconContainer {
		border-radius: 50%;
		background: #E1F3F6;
		padding: 6px 3px;
		margin-right: 10px;
	}
	.totalCostIcon.MuiSvgIcon-root {
		width: 40px;
		height: 32px;
		color: #366369;
	}
	.totalCostAmount.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-size: 26px;
		font-weight: 600;
	}
	.breakdownContainer {
		padding-left: 9px;
		flex-grow: 1;
	}
	.breakdownTitle.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-size: 16px;
		font-weight: 600;
	}
	.breakdownTable {
		align-items: center;
	}
	.breakdownModelCost {
		display: flex;
		align-items: center;
	}
	.breakdownModelName.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-size: 13px;
		font-weight: 500;
		margin-left: 3px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.breakdownAmount.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-size: 16px;
		font-weight: 600;
		margin-top: 5px;
	}


  	@media (max-width: 1199px) and (min-width: 768px) {
		.totalCostIcon.MuiSvgIcon-root {
			width: 30px;
			height: 25px;
		}
		.totalCostTitle.MuiTypography-root {
			font-size: 12px;
		}
		.totalCostAmount.MuiTypography-root {
			font-size: 22px;
		}
		.breakdownAmount.MuiTypography-root {
			font-size: 14px;
		}
  	}
	@media (max-width: 767px) {
		.totalCostIcon.MuiSvgIcon-root {
			width: 25px;
			height: 22px;
		}
		.totalCostTitle.MuiTypography-root {
			font-size: 11px;
		}
		.totalCostAmount.MuiTypography-root {
			font-size: 18px;
		}
		.breakdownAmount.MuiTypography-root {
			font-size: 13px;
		}
	}
}