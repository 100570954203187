.pricingContainer {
  background: radial-gradient(89.52% 134.44% at 22.95% 0%, rgba(161, 120, 250, 0.04) 0%, rgba(163, 117, 252, 0.04) 17.71%, rgba(107, 197, 209, 0.06) 35.86%, rgba(0, 109, 122, 0.04) 57.9%, rgba(163, 117, 252, 0.03) 91.9%), #FFF;
  height: 100vh;
  padding: 29px 31px;

  .titleRow {
    text-align: center;
  }

  .periodSelector {
    margin: 19px 0 48px;
    border-radius: 22px;
    background: #D1BAFD;
    border: 0;
    padding: 2px;

    & > button {
      border-radius: 22px;
      border: 0;

      color: #44546F;
      font-size: 14px;
      font-weight: 600;
      line-height: 133.4%;
      padding: 10px 17px;

      &.Mui-selected {
        background: #FFFFFF;
        padding: 0 27px;
      }
    }
  }

  .plansRow {
    display: flex;
    gap: 40px;

    .planContainer {
      border-radius: 9px;
      border: 2px solid #80B6BC;
      background: #FFF;
      padding: 35px 55px;

      &.selected {
        border: 4px solid #80B6BC;
        background: rgba(181, 226, 232, 0.25);
      }

      .titleContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 8px;

        .planCheckbox {
          padding: 0;
          color: var(--global-primary-color);
        }
      }

      .price {
        color: var(--global-text-primary-color);
        font-size: 25px;
        font-weight: 600;
        line-height: 133.4%;
        margin-bottom: 37px;
      }

      .planTitle > .MuiTypography-root {
        color: var(--global-text-primary-color);
        font-size: 36px;
        font-weight: 700;
        line-height: 133.4%;
        margin-left: 10px;
      }

      .benefitTitle {
        color: var(--global-text-primary-color);
        font-size: 20px;
        font-weight: 600;
        line-height: 133.4%;
      }

      .benefit {
        display: flex;
        gap: 19px;
        margin: 16px 0;
        color: var(--global-text-primary-color);
        font-size: 20px;
        font-weight: 500;
        line-height: 133.4%;
      }
    }
  }

  .verticalDivider {
    height: 95%;
    width: 1px;
    background-color: #64BFCA;
    display: flex;
    align-self: center;
    margin: 0 41px;
  }

  .horizontalDivider {
    background-color: #64BFCA;
    height: 1px;
    margin: 50px 0 22px;
  }

  .summaryContainer {
    position: relative;

    .title.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-size: 35px;
      font-weight: 700;
      line-height: 133.4%;
    }

    .seatsRow {
      display: flex;
      gap: 10px;

      .subtractButton {
        border-radius: 13px;
        background: #C4C3C5;
        border: 0;

        .subtractIcon {
          color: white;
        }
      }

      .addButton {
        border-radius: 13px;
        background: #E7E7E7;
        border: 0;

        .addIcon {
          color: black;
        }
      }
    }

    .summaryTitle {
      padding-bottom: 13px;
    }

    .contactSalesText.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-size: 19px;
      font-weight: 600;
      line-height: 133.4%;
    }

    .contactSalesButton {
      margin-top: 20px;
      width: 100%;
    }

    .price.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-size: 50px;
      font-weight: 600;
      line-height: 133.4%;
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: center;

      .continueButton {
        position: absolute;
        bottom: 0;
      }
    }
  }
}