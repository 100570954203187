.AccountCreationProgress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 100px;
    gap: 13px;

    &_Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        
        & .MuiCircularProgress-svg {
            color: #D9D9D9;
        }
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    &_Title.MuiTypography-root {
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        color: var(--global-text-primary-color);
    }

    &_Subtitle.MuiTypography-root {
        font-size: 20px;
        text-align: center;
        line-height: 26px;
        color: var(--global-text-primary-color);
    }
}
