.groupAddUsers {
    display: flex;
    flex-direction: column;
    padding: 23px 30px;
    width: 586px;
    gap: 15px;
    flex-grow: 1;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    
    .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        
        .title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .icon {
                width: 52px;
                height: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                background-color: var(--global-text-primary-color);

                & > svg {
                    color: white
                }
            }

            .MuiTypography-root {
                margin: 0;
            }
        }

        .MuiButtonBase-root {
            padding: 0;
        }
    }

    &_menuItem {

        &.MuiAutocomplete-option.MuiBox-root {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;

            .userIcon.MuiAvatar-root {
                color: white;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 166%; /* 16.6px */
                letter-spacing: 0.4px;
            }
    
            .username.MuiTypography-root {
                color: #626F86;
                font-family: var(--global-font-family);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }

    .selectedUsersList {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex-shrink: 1;

        .selectedUser {
            padding: 9px 7px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title {
                display: flex;
                flex-direction: row;
                gap: 21px;
                justify-content: flex-start;
                align-items: center;

                .MuiAvatar-root {
                    margin: 0;
                }

                .MuiTypography-root {
                    color: #626F86;
                    font-family: var(--global-font-family);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                }
            }

            .deleteIcon.MuiSvgIcon-root {
                color: #006D7A;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-shrink: 2;
        flex-grow: 1;

        .actions {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }
    }
}