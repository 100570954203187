.enabledAiModelsByGroupContainer {
  &.MuiGrid-container {
    width: 45vw;
  }


  & .enabledAiModelsGroupName {
    padding-left: 12px;
    align-self: center;

    & > h4.MuiTypography-h4 {
      margin-bottom: 0;
    }
  }

  .editGroupModelsIcon {
    background-color: #44546F;
  }
}