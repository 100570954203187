.promptInputContainer.disabled {
  border: 2px solid rgba(204, 204, 204, 1);

    .optimizationText.disabled {
      color: rgba(204, 204, 204, 1);
    }

}

.searchBar.MuiTextField-root.Mui-disabled {

  &::placeholder {
    color: rgba(204, 204, 204, 1);
  }

}

.promptSettingsRow {

  & > .promptOptimizationButton.Mui-disabled {
    background-color: #FFFFFF;
  }
}
