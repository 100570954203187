.hoverMenuTooltip.MuiTooltip-tooltip {
  color: white;
  background-color: #00373D;
}

.copyIcon.MuiIconButton-root {
  padding: 5px 8px 0 8px;
}

.hoverMenuContainer {
  position: absolute;
  right: 6px;
  top: -25px;
  max-width: fit-content;
  transition: all linear 0.5s;
}