.sideItemHelpContainer {
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    
    .sideItemHelpButton.MuiButtonBase-root {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}