.trialDialogContainer {
  backdrop-filter: blur(5px);

  & .MuiPaper-root {
    box-shadow: none;
    border: solid 1px var(--global-outline-color);
    border-radius: 4px;
    min-height: 300px;
  }

  & .trialDialogTitle {
    font-size: 24px;
    font-weight: 700;
    border-bottom: solid 1px var(--global-outline-color);
    padding: 24px;
    color: var(--global-text-primary-color);
    text-align: center;
  }

  & .trialDialogContent {
    color: var(--global-text-primary-color);
    display: flex;
    align-items: center;

    .adminTextContent.MuiTypography-root {
      font-size: 18px;
      text-align: center;
      color: var(--global-text-primary-color);
      margin: 16px 0;
    }
  }

  & .trialDialogActionsContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
  }
}

.boldText {
  font-weight: 700;
}

