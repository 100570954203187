.AuditingLogDetailPromptWarnings {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    padding-bottom: 10px;

    &_Title {
        &.MuiTypography-root {
            font-size: 14px;
            font-weight: 600;
            line-height: 19.12px;
        }
    }

    &_Reason {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.12px;
        color: #818A91;
    }

    &_WarningContainer {
        padding-left: 9px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

    &_WarningTitle {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.16px;
        
        &.high {
            color: #9A4D2F;
        }    

        &.medium {
            color: #926D34;
        }
    
        &.low {
            color: #6E6B31;
        }
    }
}