.promptCard.MuiPaper-root {
  display: flex;
  flex-direction: column;
  width: 19rem;
  height: 13rem;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &.disabled {
    cursor: auto;
  }
}

.promptCardNew.MuiPaper-root {
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23006D7AFF' stroke-width='2' stroke-dasharray='30' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
  cursor: pointer;
}

.promptCardNewText.MuiTypography-root {
  color: var(--global-primary-color);
  font-size: 20px;
  font-weight: 700;
}

.promptCardInfo {
  height: 10rem;
}

.promptCardTitle {
  &.MuiCardContent-root {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
  }

  & .promptCardIcons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    & .MuiSvgIcon-root {
      color: var(--global-primary-color);
    }
  }
}

.promptCardTitleText {
  color: var(--card-text-color);
  font-size: 16px;
  font-weight: 500;
}

.promptCardLabels.MuiCardContent-root {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}

.promptCardLabel.MuiChip-root {
  height: 1rem;
  border-radius: 4px;
  background: #6BC5D1;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promptCardText,
.promptCardText.MuiChip-label {
  color: var(--card-text-color);
  font-family: var(--global-font-family);
  font-size: 10px;
}

.promptCardButtons.MuiCardActions-root {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;

  &.disabled {
    cursor: auto;
  }
}

.promptCardDescription.MuiCardContent-root {
  padding-top: 7px;
}

.promptCardDescriptionText.MuiTypography-root {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--card-text-color);
  font-size: 10px;
}

.promptCardButton.MuiButtonBase-root {
  color: var(--global-primary-color);
  font-family: var(--global-font-family);
  font-size: 10px;
  font-weight: 600;

  &.disabled {
    background-color: #FFFFFF;
    color: rgba(204, 204, 204, 1);
  }
}

.promptCardHorizontalLine {
  border-bottom: 1px solid var(--card-text-color);
  opacity: 0.3;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.promptCardVerticalLine {
  width: 1px;
  background-color: var(--card-text-color);
  opacity: 0.3;
  height: 50%;
}

.promptCardIconButton {
  display: flex;
  align-items: center;
}

.promptCardIcon.MuiSvgIcon-root {
  width: 15px;
  color: var(--global-primary-color);

  &.disabled {
    color: rgba(204, 204, 204, 1);
  }
}

.MuiTooltip-popper {
  max-width: 250px;
}

.categoryLabel.MuiChip-root {
  border-radius: 4px;
  background: rgba(241, 234, 255, 0.96);

  & > .MuiChip-label {
    color: #523B7E;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
}