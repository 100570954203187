.dialogContainer {
  & .MuiPaper-root {
    box-shadow: none;
    border: solid 1px var(--global-outline-color);
    border-radius: 4px;
    width: 50%;
  }

  & .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--global-outline-color);
  }

  & .dialogTitle, .dialogContent {
    padding: 16px 24px !important;
    color: var(--global-text-primary-color);
  
    & > p {
      color: var(--global-text-primary-color);
    }
  }

  & .newGroupNameTextfield {
    margin-top: 20px
  }
  
  & .dialogCloseIcon {
    cursor: pointer;
  }

  & .deleteButton.noBorderDeleteButton {
    border: none;
  }
}

