.auditingLogsContainer {
  &.MuiGrid-root {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .auditingLogsHeader {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .auditingLogsTitle.MuiTypography-root {
    color: var(--global-text-primary-color);
    font-size: 24px;
    font-weight: 700;
  }
  .auditingLogsTable {
    display: flex;
  }
}


.checkIcon {
  width: 20px;
  color: #27CA40AB;
}

.cancelIcon {
  width: 20px;
  color: #FF6058AB;
}

.AuditingLogsAction_Pending {
  & > svg {
    color: #FF6633;

  }
}
