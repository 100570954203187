.searchLibrary {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;


    &_dropdownWrapper.MuiFormControl-root {
        min-width: 200px;
    }

    &_dropdownWrapperMargin {
        margin: 12px;
    }

    &_checkboxItem {
        margin-right: 5px;
    }
}