@use 'src/styles/admin';

.safeguardContainer {
    @include admin.container;

    .title.MuiTypography-root {
        @include admin.title;
        margin-bottom: 10px;
    }

    .subtitle.MuiTypography-root {
        @include admin.subtitle;
        margin-bottom: 15px;
    }

    .sectionTitle.MuiTypography-root {
        @include admin.sectionTitle;
        padding: 12px 0px;
        margin: 0px 15px;
        border-bottom: 1px solid #E0E0E0;
    }
}