.appMenuSideItemsContainer {
    padding: 30px 4px 24px 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #E7F2F3;

    .sideItemsSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .sideItemsLogo {
        width: 30px;
        height: 30px;
        display: flex;
        border-radius: 15px;
        background-color: white;

        & > svg {
            width: 30px;
            height: 30px;
        }
    }
}