.auditingLogDetailTitle.MuiTypography-root {
  color: #44546F;
  font-size: 24px;
  font-weight: 700;
  line-height: 133.4%;
  margin-left: 3px;
}

.auditingLogDetailContainer {
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  background: #FFF;
  margin-top: 5px;
}

.auditDataRow {
  display: flex;
  justify-content: space-between;
}

.auditDataFieldName.model {
  display: flex;
}

.auditDataFieldName {
  color: #44546F;
  font-size: 16px;
  font-weight: 400;

  .auditDataField {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }

  .modelName {
    display: flex;
    gap: 5px;
  }

  .warningLevelField .MuiChip-label {
    font-size: 13px;
  }
}

.auditingLogsContentContainer {
  display: flex;
  gap: 10px;

  .auditLogsContainer {
    display: flex;
    flex-direction: column;
    flex: 5 1;
    padding: 0;
  }

  .auditTrailContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 7 1;
    padding: 0;
  }

  .auditLogsListTitleContainer {
    display: flex;
    padding: 16px;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;


    .auditingLogsListTitleIcon {
      padding: 4px;
      border-radius: 9px;
      background: #FFF4E5;
      width: 29px;
      height: 29px;
      margin-right: 10px;
      color: #94440C;
    }

    .auditingLogsListTitle.MuiTypography-root {
      color: #44546F;
      font-size: 20px;
      font-weight: 700;
      line-height: 133.4%;
    }
  }

  .auditLogsListContainer {
    padding: 15px 0;
    color: #44546F;
    width: 100%;

    .MuiChip-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.16px;
    }

    .title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }

    .date {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .prompt {
      font-size: 14px;
      font-weight: 400;
      white-space: pre-line;
    }

    .MuiAccordion-root {
      box-shadow: none;
      border-bottom: 1px solid rgba(55, 65, 81, 0.07);
      border-radius: 0;
      margin: 0 16px;
    }

    .MuiAccordion-root:before {
      opacity: 0;
    }

    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionDetails-root {
      padding: 8px 0px;
    }
  }
}