@use 'src/styles/admin';

.teamsContainer {
  @include admin.container;

  .teamsContent {
    flex-grow: 1;
  }

  .teamsSearchAndListContainer {
    border-right: 1px solid rgba(68, 84, 111, 0.08);
    padding-right: 5px;
  }

  .teamsTitle.MuiTypography-root {
    @include admin.title;
    margin-bottom: 10px;
  }

  .teamsSubtitle.MuiTypography-root {
    @include admin.subtitle;
    margin-bottom: 15px;
  }

  .teamTableHeaderContainer {
    background-color: #fbfbfc;
    margin: 0 5px;
    border-bottom: 1px solid rgba(68, 84, 111, 0.08);
  }

  .teamTableHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 20px 15px 0 0;

    & > h3 {
      margin-left: 15px;
    }

    .teamTableButtonsContainer {
      display: flex;
      align-items: center;

      & > hr {
        height: 35px;
        border: 1px solid rgba(68, 84, 111, 0.2);
        margin-right: 5px;
      }

      .addUsersButton {
        margin-right: 10px;
      }
    }
  }

  .teamTableTitle.MuiTypography-root {
    color: var(--global-text-primary-color);
    font-size: 22px;
    font-weight: 600;
  }

  .newTeamButton.MuiButtonBase-root {
    color: #626F86;
    margin: 10px 0;
    width: 100%;
    justify-content: left;
  }

  .teamTabContainer {
    padding: 0 10px;

    & button {
      color: #523B7E;
      font-size: 16px;
      font-weight: 600;

      &.Mui-selected {
        border-bottom: 2px solid #523B7E;
        transition: all 0.3s ease-out;
        color: #523B7E;
      }
    }
  }

  .teamsSearchBar {
    position: relative;
    width: 100%;
    border-radius: 5px;
    background: rgba(68, 84, 111, 0.03);

    .teamsSearchIconContainer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 100%;

      & > svg {
        color: rgba(68, 84, 111, 0.80);
      }
    }

    .teamsSearchInput {
      color: inherit;
      font-size: 14px;
      font-weight: 500;

      & .MuiInputBase-input {
        width: 100%;
        padding: 10px 10px 10px 35px;
      }
    }

  }

}
