.aiModelConfig {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;

  & > p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}

.aiModelConfigHeader {
  display: flex;
  justify-content: space-between;

  & > h3 {
    color: #172B4D;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  & > svg {
    color: var(--global-primary-color);
    cursor: pointer;
  }

}

.saveAiModelButtonContainer {
  display: flex;
  height: 75vh;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  & > button {
    border-radius: 3px;
    border: 1px solid var(--Dark-Aquamarine, #006D7A);
    background: var(--Dark-Aquamarine, #006D7A);
    color: #ffffff;
    margin: 15px 0;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), var(--global-primary-color, #006D7A);
    }
  }
}

.textFieldInput.MuiTextField-root.configDrawer {
  margin-top: 15px;
}