.fileUploadChips {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }

    .fileUploadChip {
        &.MuiChip-root {
            border-radius: 4px;
            .MuiChip-label {
                font-family: var(--global-font-family);
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.16px;
            }
            
        }
        &.MuiChip-root:not(.MuiChip-colorError) {
            background-color: #44546F0D;

            .MuiChip-label {
                color: var(--global-text-primary-color);
            }
            .MuiChip-deleteIcon {
                color: var(--global-text-primary-color);
                opacity: 0.7;
            }
            .MuiChip-icon {
                color: var(--global-text-primary-color);
            }
            
        }
    }
}