.saveChatContainer {
  padding: 23px 24px;
  width: 40vw;
  height: 100%;
}

.saveChatDescription.MuiPaper-root {
  padding: 8px 15px;
  border-radius: 0 0 15px 15px;
  background-color: rgba(209, 186, 253, 0.30);
  box-shadow: none;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.categoryCreatableSelect {
  & input, & input:focus  {
    box-shadow: none;
  }

  & [class$="-placeholder"] {
    font-family: var(--global-font-family);
    color: rgba(0, 0, 0, 0.30);
    padding: 14.5px 6px;
  }
}

.saveChatTitle {
  display: flex;
  justify-content: space-between;
}

.saveChatDescriptionText {
  margin-bottom: 15px;
  font-size: 16px;
}

.textFieldChatDescription .MuiOutlinedInput-input {
  box-shadow: none;
}

.saveChatButtonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex-grow: 1;
  justify-content: flex-end;
  align-self: flex-end;
}

.saveChatDescription {
  margin: 10px 0;
}