.advancedSettingsDialogContainer > div > div {
  min-width: 800px;
  padding: 12px 20px;

  & > h2 {
    font-weight: 600;
  }
}

.advancedSettingsButtons {
  margin-top: 20px;
}

.slider {
  padding: 0 15px;
}

.advancedSettingsCloseButton.MuiButtonBase-root,
.advancedSettingsSaveButton.MuiButtonBase-root,
.advancedSettingsCloseButton.MuiButtonBase-root:hover,
.advancedSettingsSaveButton.MuiButtonBase-root:hover {
  border: none;
  font-weight: 600;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .advancedSettingsDialogContainer > div > div {
    min-width: 700px;
    padding: 10px 18px;
  }
}

@media (max-width: 767px) {
  .advancedSettingsDialogContainer > div > div {
    min-width: 90%;
    padding: 8px 15px;
  }
}