.AuditLogWarningLevelChip {
    &_High {
      &.MuiChip-root {
        border-radius: 8px;
        background-color: #F3C6B5;
      }
  
      & .MuiChip-label {
        color: #9A4D2F;
      }
    }
  
    &_Medium {
      &.MuiChip-root {
        border-radius: 8px;
        background-color: #EED9BA;
      }
  
      & .MuiChip-label {
        color: #926D34;
      }
    }
  
    &_Low {
      &.MuiChip-root {
        border-radius: 8px;
        background-color: #EEECBA;
      }
  
      & .MuiChip-label {
        color: #6E6B31;
      }
    }
  }