@use 'src/styles/admin';

.budgetControlContainer {
  @include admin.container;

  .title {
	@include admin.title;
	margin-bottom: 10px;
  }

  .subtitle {
	@include admin.subtitle;
	margin-bottom: 15px;
  }

  .buttonsRow {
	text-align: right;
	& > button {
	  margin: 0 7.5px;

	  &.disabled {
		color: rgba(0, 0, 0, 0.26);
		border: 1px solid rgba(0, 0, 0, 0.12);
	  }
	}
  }

  .organizationBudgetContainer {
	display: flex;
	padding: 13px 24px;
	align-items: flex-start;
	gap: 3px;
	flex: 1 0 0;
	border-radius: 12px;
	border: 1px solid rgba(68, 84, 111, 0.15);
	margin-bottom: 20px;

	& .budgetIconContainer {
	  border-radius: 52px;
	  padding: 35px;
	  background: #F5F4F7;
	  margin-right: 10px;
	}

	& .budgetLimitContainer {
	  display: flex;
	  flex-direction: column;

	  & .title {
		color: var(--global-text-primary-color);
		font-size: 18px;
		font-weight: 600;
	  }

	  & .subtitle {
		color: var(--global-text-primary-color);
		font-size: 30px;
		font-weight: 600;

		& svg {
		  margin-bottom: 7px;
		}
	  }
	}
	.renewedBudget {
	  color: var(--global-text-primary-color);
	  font-size: 14px;
	  font-weight: 400;
	  line-height: 20px;
	}
  }
  .noTeamsButtonContainer > button {
	margin: 0 5px;
  }

  .deleteBudgetContainer {
	align-self: center;
	flex-grow: 1;

	& button {
	  float: right;
	}
  }

  .budgetControlNoTeams {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
  }
}