.usageTableContainer {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(68, 84, 111, 0.15);
}

.usageTableTitle.MuiTypography-root {
  padding-bottom: 16px;
  color: var(--global-text-primary-color);
  font-size: 24px;
  font-weight: 700;
}

.usageTableToolbarContainer {
  display: flex;
  & > .MuiDataGrid-toolbarQuickFilter {
    margin-left: auto;
  }
}